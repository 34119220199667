
import { defineComponent } from "vue";
import EditCoinRateEntryForm from "@/views/coins/constituent/EditCoinRateEntryForm.vue";

export default defineComponent({
  name: "EditCoinRateEntry",
  components: {
    EditCoinRateEntryForm,
  },
  setup() {
    return {};
  },
});
