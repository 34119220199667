<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Edit Coin Rate Entry</h1>
    </div>
    <EditCoinRateEntryForm />
  </section>
</template>
    
    <script lang="ts">
import { defineComponent } from "vue";
import EditCoinRateEntryForm from "@/views/coins/constituent/EditCoinRateEntryForm.vue";

export default defineComponent({
  name: "EditCoinRateEntry",
  components: {
    EditCoinRateEntryForm,
  },
  setup() {
    return {};
  },
});
</script>
    
    <style scoped></style>
    