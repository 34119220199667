
import { defineComponent, onBeforeMount, ref } from "vue";
import { apiGet, apiPut } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "EditCoinEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const route = useRoute();

    const formData = ref({
      id: null,
      maximum_usd_Value: null,
      minimum_usd_value: null,
      rate: null,
      is_active: null,
    });
    const loaded = ref(false);
    const formError = ref({});
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        await apiPut(`/admin/coin/rate/${route.params.coinRateId}`, {
          isActive: formData.value.is_active,
          minimumUsdValue: formData.value.minimum_usd_value,
          maximumUsdValue: formData.value.maximum_usd_Value,
          rate: formData.value.rate,
        });
        ShowSuccess("Entry Updated Successfully");
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to update entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/coin/rate?coinTypeId=${route.params.coinTypeId}`
        );

        const entry = response.data.data.coinRates;

        formData.value = entry.find(
          (entry: { id: string }) => entry.id == route.params.coinRateId
        );

        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Card Entry fetched",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);
    return {
      Submit,
      RefreshData,
      loaded,
      formData,
      formError,
      loading,
    };
  },
});
